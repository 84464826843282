<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1>
          Oups!</h1>
        <h2>
          404</h2>
        <div class="error-details">
         Désolé, la page demandé n'existe pas
        </div>
        <div class="error-actions">
          <button mat-raised-button color="primary" [routerLink]="['/']">Retourner sur le site</button>
        </div>
      </div>
    </div>
  </div>
</div>
