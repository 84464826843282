import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {GlobalParameter} from '../GlobalParameter';
import {Category} from '../models/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private http: HttpClient, private parameter: GlobalParameter) {
  }

  GetCategories() {
    return this.http.get<Category[]>(
      this.parameter.restApiHost + '/api/categories/all');
  }

  createCategory(category: Category) {
    return this.http.post<Category>(this.parameter.restApiHost + '/api/categories', category)
  }
  deleteCategory(category: Category)
  {
    this.http.post<Category>(this.parameter.restApiHost + '/api/categories/delete', category).subscribe(response => {
    });
  }

}
