
<div class="content">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Login</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form>
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Username" [(ngModel)]="username" name="username" required>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Password" [(ngModel)]="password" type="password" name="password" required>
                            </mat-form-field>
                        </td>
                    </tr>
                    <p *ngIf="error" class="error">
                        {{ error }}
                    </p>
                </table>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="checkLogin()" color="primary">Login</button>
        </mat-card-actions>
    </mat-card>
</div>

