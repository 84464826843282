<ng-template *ngIf="article == null; else elseBlock">
  <mat-spinner></mat-spinner>
</ng-template>

<ng-template #elseBlock>
  <section class="container ">

    <div class="row justify-content-md-center">
      <div class=" col-xl-8 col-lg-8 col-md-12 col-sm-12 d-flex flex-column ">
        <div class="mt-3">
          <div class="col-12">
            <mat-card class="mb-4">
              <div class="d-flex justify-content-center flex-column align-items-center">
                <h2>{{article.name}}</h2>
                <mat-card-subtitle>{{article.category.name}}</mat-card-subtitle>
              </div>
              <mat-card-content class="d-flex justify-content-center flex-column align-items-center ">
                <div class="container-article">
                  <img class="col-12" src="{{globalParameter.restApiHost}}/api/articles/image/{{article.id}}">

                  <markdown class="col-12" [data]="article.content"></markdown>

                </div>
              </mat-card-content>
            </mat-card>
          </div>

        </div>
      </div>
      <div class="content-side-right col-xl-4 col-lg-4 col-md-12 col-sm-12  d-flex justify-content-center">
        <div class="mt-3">
          <div class="col-12">
            <mat-card class="mb-4">
              <div class="d-flex justify-content-center">
                <h2 class="text-center">Qui suis-je ?</h2>
              </div>
              <mat-card-content class=" mt-3 mb-3 d-flex justify-content-center">
                <p class="text-center">
                  {{globalParameter.whoIam}}
                </p>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="col-12">
            <mat-card class=" mb-4">
              <div class="d-flex justify-content-center">
                <h2 class="text-center">Réseaux sociaux</h2>
              </div>
              <mat-card-content class=" mt-3 mb-3 d-flex justify-content-center">
                <div  class="text-center d-flex justify-content-around">
                  <a mat-fab class="mat-fab" color="primary" href="https://www.instagram.com/theoounoughi/">
                    <mat-icon svgIcon="instagram" aria-hidden="false"></mat-icon>
                  </a>
                  <a mat-fab class="mat-fab" color="primary" href="https://www.youtube.com/channel/UCB6b--Hc3Fu6RPGULDELqmA">
                    <mat-icon svgIcon="youtube" aria-hidden="false"></mat-icon>
                  </a>

                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="col-12">
            <mat-card class=" mb-4">
              <div class="d-flex justify-content-center">
                <h2 class="text-center">Article épinglé</h2>
              </div>
              <mat-card-content class="mt-3 mb-3 d-flex justify-content-center">
                <div class="text-center d-flex justify-content-around">
                  <ng-template *ngIf="pinnedArticle.length == 0; else elseBlockPinned">
                    <p>Pas d'article épinglé !</p>
                  </ng-template>
                  <ng-template #elseBlockPinned>
                    <mat-list>
                      <div  *ngFor="let article of pinnedArticle; let i=index;let isOdd = odd;">
                        <div *ngIf="i<5">
                          <div *ngIf="isOdd"><mat-divider></mat-divider></div>
                          <mat-list-item><a mat-button class="link-pinned-article" [routerLink]="['/article/'+article.id]" target="_blank">{{article.name}}</a></mat-list-item>
                          <div *ngIf="isOdd"><mat-divider></mat-divider></div>
                        </div>
                      </div>
                    </mat-list>
                  </ng-template>

                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
