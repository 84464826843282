import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalParameter} from '../GlobalParameter';
import {Article} from '../models/Article';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  constructor(private http: HttpClient, private parameter: GlobalParameter) {
  }

  getArticles(){
    return this.http.get<Article[]>(
      this.parameter.restApiHost + '/api/articles/all'
    );
  }


  createArticle(article: Article) {
    return this.http.post<Article>(this.parameter.restApiHost + '/api/articles', article)
  }

  deleteArticle(article: Article)
  {
    this.http.post<Article>(this.parameter.restApiHost+ '/api/articles/delete',article).subscribe(response => {

    });
  }

  updateArticle(article: Article) {
    return this.http.post<Article>(this.parameter.restApiHost+'/api/articles/update',article);
  }

  getArticleById(articleID: string) {
    return this.http.get<Article>(this.parameter.restApiHost+'/api/articles/get/'+articleID);
  }

  uploadImage(fileToUpload: File,article:Article) {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, String(article.id));

    return this.http.post<Article>(this.parameter.restApiHost+"/api/articles/image/upload", formData);

  }
}
