import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {AngularMaterialModule} from './angular-material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BasicAuthHttpInterceptorService} from './service/basic-auth-interceptor.service';
import { MatExpansionModule } from '@angular/material/expansion';
import {NgxEchartsModule} from 'ngx-echarts';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MainPageComponent} from './main-page/main-page.component';
import {ArticlesComponent} from './articles/articles.component';
import {AdminPanelComponent, PreviewArticleComponent} from './admin-panel/admin-panel.component';
import {MarkdownModule} from 'ngx-markdown';
import {DialogComponent} from './dialog/dialog.component';
import {Error404Component} from './error404/error404.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        MainPageComponent,
        ArticlesComponent,
        AdminPanelComponent,
        PreviewArticleComponent,
        DialogComponent,
        Error404Component
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        MatExpansionModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        NgbModule,
        ScrollingModule,
        MarkdownModule.forRoot(),

    ],
    providers: [{provide: HTTP_INTERCEPTORS, useClass: BasicAuthHttpInterceptorService, multi: true}],
    bootstrap: [AppComponent]
})
export class AppModule {
}
