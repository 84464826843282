<section class="container-fluid">
  <div class="row d-flex align-items-start">
    <div class="content-article col-6 d-flex align-items-center flex-column ">
      <div class="col-12">
        <mat-card class=" mb-4">
          <mat-card-header>
            <mat-card-title>Article</mat-card-title>
            <button mat-raised-button class="color-button button align-self-start" (click)="changeStatus()">Crée un
              article
            </button>
          </mat-card-header>
          <mat-card-content class="mt-3 mb-3 d-flex justify-content-center justify-content-around">
            <form class="container">
              <div class="row ">
                <div class="col-12 d-flex align-items-center">
                  <mat-form-field class="col-9" appearance="fill" [floatLabel]="floatLabelControl.value">
                    <mat-label>Titre de l'article</mat-label>
                    <input [formControl]="formControlTitle" required matInput placeholder="Titre d'article">
                  </mat-form-field>



                  <div class="col-3 mb-3 import-file">
                    <mat-label>{{labelImage}}</mat-label>
                    <button class="fileButton" type="button" mat-raised-button (click)="fileInput.click()">Selectionner
                      une image
                    </button>
                    <input required hidden (change)="onFileSelected($event.target.files)" #fileInput type="file"
                           id="file">
                  </div>

                </div>

                <mat-form-field class="col-5" appearance="fill" [hideRequiredMarker]="hideRequiredControl.value"
                                [floatLabel]="floatLabelControl.value">
                  <mat-select [formControl]="formControlCategory" required>
                    <div *ngFor="let category of categories">
                      <mat-option value="{{category.name}}">{{category.name}}</mat-option>
                    </div>
                  </mat-select>
                  <mat-label>Categorie de l'article</mat-label>
                </mat-form-field>

                <mat-checkbox class="example-margin" [formControl]="formControlPinned">Epinglé</mat-checkbox>

                <mat-form-field class="col-12" appearance="fill" [floatLabel]="floatLabelControl.value">
                  <mat-label>Contenu de l'article</mat-label>

                  <textarea [formControl]="formControlContent" maxlength="10000" required style="height: 400px" matInput
                            placeholder="Contenu de l'article"></textarea>
                </mat-form-field>

              </div>
              <div class="d-flex justify-content-end">

                <button mat-raised-button class="color-button button" (click)="openPreview()">Preview</button>
                <button mat-raised-button class="color-button button"
                        (click)="updateArticle()">{{getButtonName()}}</button>
              </div>

            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="content-side-right col-6  d-flex justify-content-center flex-column">
      <div class="col-12">
        <mat-card class=" mb-4">
          <mat-card-header>
            <mat-card-title>Articles par catégorie</mat-card-title>
          </mat-card-header>
          <mat-card-content class="mt-3 mb-3 d-flex justify-content-center flex-column">
            <cdk-virtual-scroll-viewport [itemSize]="18 * 7" class="example-viewport">
              <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <!-- use a disabled button to provide padding for tree leaf -->

                  <div class="tree-node-content">
                    <button mat-icon-button disabled></button>
                    <p>{{node.name}}</p>
                    <div *ngIf="isArticle(node)">
                      <button mat-icon-button color="primary" (click)="onEditArticle(node)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="!isMainCategory(node)">
                      <button mat-icon-button color="primary" (click)="onDeleteContent(node)">
                        <mat-icon>remove_circle</mat-icon>
                      </button>
                    </div>
                  </div>


                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>

                  <div class="tree-node-content">
                    <button mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'Toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                      </mat-icon>
                    </button>
                    <p style="font-weight: bold">{{node.name}}</p>
                    <div *ngIf="!isMainCategory(node)">
                      <button mat-icon-button color="primary" (click)="onDeleteContent(node)">
                        <mat-icon>remove_circle</mat-icon>
                      </button>
                    </div>
                  </div>

                </mat-tree-node>
              </mat-tree>
            </cdk-virtual-scroll-viewport>
            <form class="d-flex flex-column  align-items-center">
              <h3>Crée une catégorie</h3>
              <mat-form-field style="width: 60%" appearance="fill" [floatLabel]="floatLabelControl.value">
                <mat-label>Nom de la categorie</mat-label>
                <input [formControl]="formControlCreateCategory" matInput placeholder="Nom de la categorie">
              </mat-form-field>
              <button style="width: 30%" mat-raised-button class="color-button" (click)="createCategory()">Crée la
                catégorie
              </button>
            </form>
          </mat-card-content>
        </mat-card>
      </div>

    </div>
  </div>
</section>
