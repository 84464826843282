<mat-toolbar>
  <button [routerLink]="['/']" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>home</mat-icon>
  </button>
  <span class="spacer"></span>
  <span>ssc</span>
  <span class="spacer"></span>
  <button [routerLink]="['/admin']" mat-icon-button class="favorite-icon"
          aria-label="Example icon-button with heart icon">
    <mat-icon>perm_identity</mat-icon>
  </button>
</mat-toolbar>

<router-outlet></router-outlet>


