import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CategoriesService} from '../service/categories.service';
import {Category} from '../models/Category';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatNode} from './FlatNode';
import {ContentNode} from './ContentNode';
import {Article} from '../models/Article';
import {ArticlesService} from '../service/articles.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from './DialogData';
import {DialogComponent} from '../dialog/dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GlobalParameter} from '../GlobalParameter';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  private _transformer = (node: ContentNode, level: number) => {
    let isMain: boolean = node.name == '';
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      data: node,
      isMain: isMain
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  floatLabelControl = new FormControl('auto');
  hideRequiredControl = new FormControl(false);

  categories: Category[] = [];
  articles: Article[] = [];
  fileToUpload: File = null;
  selectArticle: Article;

  formControlContent = new FormControl();
  formControlTitle = new FormControl();
  formControlCategory = new FormControl();
  formControlCreateCategory = new FormControl();
  formControlPinned = new FormControl();
  selectedCategory: string;

  pinnedArticle: Article[] = [];

  labelImage: string = 'Aucune image selectionné';

  constructor(private categoriesService: CategoriesService, private articlesService: ArticlesService, public dialog: MatDialog, private _snackBar: MatSnackBar, public globalParameter: GlobalParameter) {
  }

  ngOnInit(): void {
    this.categoriesService.GetCategories().subscribe(response => {
      this.categories = response;

      this.articlesService.getArticles().subscribe(response => {

        this.articles = response;
        this.dataSource.data = this.categoriesToContentNode();

      });
    });
  }

  categoriesToContentNode(): ContentNode[] {
    let data: ContentNode[] = [];
    for (let category of this.categories) {
      data.push({name: category.name, data: category, children: this.articleByCategoryToContentNode(category)});
    }
    return data;
  }

  articleByCategoryToContentNode(category: Category): ContentNode[] {
    let articlesNode: ContentNode[] = [];

    for (let article of this.articles) {
      if (article.category.categoryID == category.categoryID) {
        articlesNode.push({name: article.name, data: article});
      }
    }

    return articlesNode;
  }

  onFileSelected(files: FileList) {
    this.fileToUpload = files.item(0);
    this.getImageName();
  }

  onDeleteContent(node) {

    //article id
    if (node.data.data.id == null) {
      let selectCategory: Category = node.data.data;
      let articleTemp: Article[] = [];
      let tempCatego: Category;

      for (let category of this.categories) {
        if (category.categoryID == 1) {
          tempCatego = category;
          break;
        }
      }

      for (let article of this.articles) {

        if (article.category.categoryID == selectCategory.categoryID) {
          article.category = tempCatego;
        }
        articleTemp.push(article);
      }

      this.categories.forEach((value, index) => {
        if (value.categoryID == selectCategory.categoryID) {
          this.categoriesService.deleteCategory(value);
          this.categories.splice(index, 1);
        }
      });


      this.dataSource.data = this.categoriesToContentNode();
    } else {
      let selectArticle: Article = node.data.data;
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {content: 'voulez-vous supprimer cette article?', yesNo: false}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteArticle(selectArticle);
          this.articlesService.deleteArticle(selectArticle);
          this.changeStatus();
          this.dataSource.data = this.categoriesToContentNode();
        }
      });
    }
  }

  onEditArticle(node) {
    this.fileToUpload = null;
    this.selectArticle = node.data.data;
    this.formControlPinned.setValue(this.selectArticle.pinned);
    this.formControlTitle.setValue(this.selectArticle.name);
    this.formControlCategory.setValue(this.selectArticle.category.name);
    this.formControlContent.setValue(this.selectArticle.content);
    this.getImageName();
  }

  deleteArticle(article: Article) {
    this.articles.forEach((value, index) => {
      if (value.id == article.id) {
        this.articles.splice(index, 1);
      }
    });
  }

  isMainCategory(node) {

    if (node.data.data.categoryID != null) {
      return node.data.data.categoryID == 1;
    }
    return false;
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  isArticle(node: FlatNode) {
    return node.data.data.id != null;
  }  ;

  openPreview() {
    if (this.fileToUpload != null) {
      var reader = new FileReader();

      reader.readAsDataURL(this.fileToUpload);
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.dialog.open(PreviewArticleComponent, {
          data: {
            title: this.formControlTitle.value,
            url: event.target.result,
            category: this.formControlCategory.value,
            markdownContent: this.formControlContent.value
          }
        });
      };
    } else if (this.selectArticle != null) {
      this.dialog.open(PreviewArticleComponent, {
        data: {
          title: this.formControlTitle.value,
          url: this.globalParameter.restApiHost + '/api/articles/image/' + this.selectArticle.id,
          category: this.formControlCategory.value,
          markdownContent: this.formControlContent.value
        }
      });
    } else {
      this.dialog.open(PreviewArticleComponent, {
        data: {
          title: this.formControlTitle.value,
          url: '',
          category: this.formControlCategory.value,
          markdownContent: this.formControlContent.value
        }
      });
    }

  }

  createCategory() {
    let categoryName: string = this.formControlCreateCategory.value;
    let error: boolean = false;
    this.categories.forEach((value => {
      if (value.name == categoryName) {
        this.formControlCreateCategory.setErrors({'alreadyExist': true});
        error = true;
        return;
      }
    }));
    if (!error) {
      this.categoriesService.createCategory({categoryID: null, name: categoryName}).subscribe(response => {
        if (response != null) {
          this.categories.push({categoryID: response.categoryID, name: response.name});
          this.dataSource.data = this.categoriesToContentNode();
        }
      });
    }
  }

  updateArticle() {
    if (this.formControlContent.valid && this.formControlTitle.valid && this.formControlCategory.valid) {
      let category: Category;
      this.categories.forEach((value => {
        if (value.name == this.formControlCategory.value) {
          category = value;
        }
      }));
      if (this.selectArticle != null) {
        this.selectArticle.pinned = this.formControlPinned.value;
        this.selectArticle.name = this.formControlTitle.value;
        this.selectArticle.content = this.formControlContent.value;
        this.selectArticle.category = category;
        this.articlesService.updateArticle(this.selectArticle).subscribe(response => {
          if (response != null) {
            if (this.fileToUpload != null) {
              this.articlesService.uploadImage(this.fileToUpload, response).subscribe(value => {
              });
            }
            this.dataSource.data = this.categoriesToContentNode();
          }
        });
      } else {
        this.selectArticle = {
          id: null,
          name: this.formControlTitle.value,
          category: category,
          content: this.formControlContent.value,
          image: '',
          author: null,
          pinned: this.formControlPinned.value,
        };

        this.articlesService.createArticle(this.selectArticle).subscribe(response => {
          if (response != null) {
            if (this.fileToUpload != null) {
              this.articlesService.uploadImage(this.fileToUpload, response).subscribe(value => {
                this.articles.push(value);
                this.selectArticle = value;
              });
            }
            else
            {
              this.selectArticle = response;
              this.articles.push(response);
            }
            this.dataSource.data = this.categoriesToContentNode();
          }
        });
        this.changeStatus()
      }

    } else {
      this.openSnackBar('Les champs sont vides', 'fermé');
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getButtonName() {
    if (this.selectArticle != null) {
      return 'Mettre a jour l\'article';
    } else {
      return 'Crée l\'article';
    }
  }

  changeStatus() {
    this.selectArticle = null;
    this.fileToUpload = null;
    this.formControlTitle.reset()
    this.formControlCategory.reset()
    this.formControlTitle.reset()
    this.formControlContent.reset()
    this.getImageName();
  }

  getImageName() {
    if (this.selectArticle != null) {
      if (this.selectArticle.image != 'unknown.jpeg') {
        this.labelImage = 'une image existe pour cet article';
        return;
      }
    }
    this.labelImage = 'Aucune image selectionné';

  }
}


@Component({
  selector: 'preview-article-component',
  templateUrl: 'preview-article-component.html',
})
export class PreviewArticleComponent {
  constructor(
    public dialogRef: MatDialogRef<PreviewArticleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }
}
