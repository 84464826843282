import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../service/authentication.service';
import {root} from 'rxjs/internal-compatibility';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    username = '';
    password = '';
    invalidLogin = false;

    @Input() error: string | null;

    constructor(private router: Router,
                private loginservice: AuthenticationService) {

    }

    ngOnInit() {

    }

    checkLogin() {
        (this.loginservice.authenticate(this.username, this.password).subscribe(
                data => {
                    this.router.navigate(['']);
                    this.invalidLogin = false;
                },
                error => {
                    this.invalidLogin = true;
                    this.error = error.message;

                }
            )
        );

    }



}
