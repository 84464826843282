import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {AuthGuardService} from './service/auth-gaurd.service';

import {MainPageComponent} from './main-page/main-page.component';
import {ArticlesComponent} from './articles/articles.component';
import {AdminPanelComponent} from './admin-panel/admin-panel.component';
import {Error404Component} from './error404/error404.component';

const routes: Routes = [
    {path: '', component: MainPageComponent},
    {path: 'login', component: LoginComponent},
    {path: 'article/:UID', component: ArticlesComponent},
    {path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService]},
    {path: 'admin',component: AdminPanelComponent, canActivate: [AuthGuardService]},
    {path: '404',component: Error404Component},
    {path: '**', redirectTo: '/404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
