import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Article} from '../models/Article';
import {ArticlesService} from '../service/articles.service';
import {PageEvent} from '@angular/material/paginator';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {GlobalParameter} from '../GlobalParameter';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit  {
  centered = false;
  disabled = false;
  unbounded = false;
  articles : Article[] = [];
  pinnedArticle : Article[] = [];

  maxArticlePorPage: number = 5;
  pageIndex : number = 0;
  pageEvent: PageEvent;
  maxLength : number= 10;
  minIndex : number = 0;
  maxIndex : number = 4;

  constructor(private articleService: ArticlesService,iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public router: Router,public globalParameter: GlobalParameter) {
    this.minIndex = this.pageIndex*4;
    this.maxIndex = (this.pageIndex+1)*4;
    iconRegistry.addSvgIcon('instagram', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/instagram.svg'));
    iconRegistry.addSvgIcon('youtube', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/youtube.svg'));

    this.articleService.getArticles().subscribe(response => {
      this.articles = response;
      this.maxLength = this.articles.length;
      response.forEach((article => {
        if (article.pinned) {
          this.pinnedArticle.push(article);
        }
      }));
    });
  }

  ngOnInit(): void {

  }


  setPageSizeOptions() {
    this.pageIndex = this.pageEvent.pageIndex
    this.minIndex = this.pageIndex*this.maxArticlePorPage;
    this.maxIndex = (this.pageIndex+1)*this.maxArticlePorPage;
    this.router.navigate(['./']);
  }

}


