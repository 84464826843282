import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {AuthenticationService} from './service/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private router: Router,private activatedRoute:ActivatedRoute,private authenticationService: AuthenticationService) {
  }

  public logout(): void {
    this.authenticationService.logOut();
    this.router.navigate(['login']);
  }
}
