import {Component, OnInit} from '@angular/core';
import {ArticlesService} from '../service/articles.service';
import {CategoriesService} from '../service/categories.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Article} from '../models/Article';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {GlobalParameter} from '../GlobalParameter';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  article: Article;
  pinnedArticle: Article[] = [];


  constructor(private articleService: ArticlesService, private categoryService: CategoriesService, private activatedRoute: ActivatedRoute, public router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public globalParameter: GlobalParameter) {
    iconRegistry.addSvgIcon('instagram', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/instagram.svg'));
    iconRegistry.addSvgIcon('youtube', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/youtube.svg'));
    this.activatedRoute.params.subscribe(root => {
      if (root['UID'] != null) {
        this.articleService.getArticleById(root['UID']).subscribe(value => {
          if (value != null) {
            this.article = value;
          } else {
            this.router.navigate(['./404']);
          }
        });
      } else {
        this.router.navigate(['./404']);
      }

    });

    this.articleService.getArticles().subscribe(value => {

      value.forEach((article => {
        if (article.pinned) {
          this.pinnedArticle.push(article);
        }
      }));
    });
  }

  ngOnInit(): void {

  }

}
