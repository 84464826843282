import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalParameter {
   restApiHost: string = "https://lorinx.zoltowski.fr/";
  //restApiHost: string = "http://localhost:8080";
  whoIam: string = "Espace de contenus monté par des skaters et chercheurs pour des amateurs de sports de glisse et sports extrêmes. " +
    "Il y'aura de nombreux articles traitant de l'actualité intéressante des sports extrêmes tous écrits par des gens internes à la discipline sans parti pris.  " +
    "Le but est de partager et former un espace collectif de fédération de nos jeunes sportifs autour de nombreuses passions qui n'ont pas besoin d'organisations, " +
    "pour subvenir à leurs besoins. Nous traiterons de news à propos de matériel de skate, bmx... de news sportives, d'avancées dans la science concernant le domaine du sport, " +
    "et d'articles pour s'instruire sur la meilleure manière de tenir en longévité dans des sports qui sont exigeants sur le corps.";
}
